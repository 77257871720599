<template>
  <v-dialog v-model="dialog" width="800" persistent>
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-tooltip bottom v-if="$route.name == 'Forms'">
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            v-blur
            class="msaBlue white--text"
            v-bind="attrs"
            v-on="{ ...tooltip, ...dialog }"
            @click="getAllFormTemplates()"
          >
            <v-icon> mdi-text-box-plus-outline </v-icon>
          </v-btn>
        </template>
        <span>Fill Out New</span>
      </v-tooltip>
      <v-btn
        class="lightBg msaBlue--text"
        v-else
        v-bind="attrs"
        v-blur
        v-on="{ ...dialog }"
        @click="getAllFormTemplates()"
      >
        fill out new form
      </v-btn>
    </template>
    <v-card>
      <v-footer
        padless
        tile
        class="msaBlue white--text"
        style="position: sticky; top: 0; z-index: 999"
      >
        <v-container fluid>
          <v-row align="center">
            <v-col class="text-h6 pl-6 grow"> Select Form Template </v-col>
            <v-col class="shrink pr-6">
              <v-btn icon @click="close">
                <v-icon color="white"> mdi-close </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-footer>
      <v-card-text class="mt-4">
        <v-row>
          <v-col cols="12">
            <SearchBar
              @search="onSearch($event)"
              searchLabel="Search by form title..."
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-expansion-panels>
              <v-expansion-panel
                v-for="group in groupedFormTemplates"
                :key="group.groupId"
                class="my-1"
              >
                <v-expansion-panel-header
                  class="msaGrey"
                  hide-actions
                  v-slot="{ open }"
                >
                  <v-row no-gutters align="center">
                    <v-col class="shrink">
                      <v-icon v-if="open">mdi-minus-thick</v-icon>
                      <v-icon v-else>mdi-plus-thick</v-icon>
                    </v-col>
                    <v-col class="grow pl-4 font-weight-medium">
                      {{ group.groupName }}
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-card>
                    <v-simple-table>
                      <template v-slot:default>
                        <tbody>
                          <tr
                            v-for="formTemplate in group.formTemplates"
                            :key="formTemplate.id"
                          >
                            <td>
                              {{ formTemplate.name }}
                            </td>
                            <td align="right" :style="{ width: '40px' }">
                              <v-btn
                                @click="fillOutForm(formTemplate)"
                                class="msaBlue white--text"
                              >
                                select
                              </v-btn>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import SearchBar from '@/components/SearchBar.vue';
export default {
  name: 'FillOutNewDialog',
  components: {
    SearchBar,
  },
  data() {
    return {
      groupedFormTemplates: [],
      search: '',
      dialog: false,
    };
  },
  methods: {
    getAllFormTemplates() {
      const params = {
        loaderText: 'Loading...',
        search: this.search,
        isFillingOut: 1,
      };

      const url = 'get-all-form-templates?format=json';
      this.$axios
        .post(url, params)
        .then((response) => {
          this.groupedFormTemplates = response.data.groups;

          if (
            !response.data.hideQuickNoteHazard &&
            'quick note hazard(s)'.includes(this.search.trim().toLowerCase())
          ) {
            this.groupedFormTemplates.unshift({
              formTemplates: [
                {
                  groupId: 0,
                  groupName: 'Quick Note Hazard(s)',
                  groupPosition: 0,
                  id: 0,
                  isOrdering: false,
                  isPublished: 1,
                  name: 'Quick Note Hazard(s)',
                  organizationId: 92,
                  position: 1,
                  updatedAt: '',
                },
              ],
              groupId: 0,
              groupName: 'Quick Note Hazard(s)',
              groupPosition: 0,
              isOrdering: false,
            });
          }
        })
        .catch((error) => error);
    },
    fillOutForm(formTemplate) {
      const params = {
        loaderText: 'Generating new form...',
      };
      const isQuickNoteHazard = formTemplate.id == 0;
      let url = '';
      if (isQuickNoteHazard) {
        url = 'create-quick-note-hazard?format=json';
      } else {
        params.formTemplateId = formTemplate.id;
        url = 'create-form-instance?format=json';
      }

      this.$axios.post(url, params).then((response) => {
        this.$store.commit('updateSelectedFormInstance', {
          name: formTemplate.name,
        });
        this.$router.push({
          name: 'FormDetails',
          params: {
            id: response.data,
          },
        });
      });
    },
    onSearch(search) {
      this.search = search;
      this.getAllFormTemplates();
    },
    close() {
      this.dialog = false;
      this.groupedFormTemplates = [];
      this.search = '';
    },
  },
};
</script>
